import React from 'react'
import {
   Modal,
   ModalOverlay,
   ModalContent,
   ModalHeader,
   ModalFooter,
   ModalBody,
   ModalCloseButton,
   useDisclosure,
   Flex,
   Alert,
   Button,
   AlertIcon
} from '@chakra-ui/react'

import { useEffect } from 'react'

const ApolloErrorComponent = ({ errors, setErrors }) => {
   const { isOpen, onOpen, onClose } = useDisclosure()

   useEffect(() => {
      console.log('why')
      if (errors && !isOpen) {
         onOpen()
      }
   }, [errors])

   return errors ? (
      <Modal isOpen={isOpen} onClose={onClose}>
         <ModalOverlay />
         <ModalContent>
            <ModalHeader>Error</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
               <Flex p='0px' direction='column'>
                  {errors &&
                     errors.map((err, i) => (
                        <Alert
                           status='error'
                           variant='solid'
                           title={err.title}
                           key={`error-${i}`}
                           mb='4px'
                        >
                           <AlertIcon />
                           {JSON.stringify(err.message)}
                        </Alert>
                     ))}
               </Flex>
            </ModalBody>
            <ModalFooter>
               <Button
                  variantColor='error'
                  onClick={() => {
                     setErrors(null)
                  }}
               >
                  OK
               </Button>
            </ModalFooter>
         </ModalContent>
      </Modal>
   ) : null
}

export default ApolloErrorComponent
