import React from 'react';
import { Field } from '../../components/Form';
import { useToast, Container, Box } from '@chakra-ui/react';
import EditView from '../../components/EditView';
import setup from './setup';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useLazyQuery, useQuery, useMutation, gql } from '@apollo/client';

const FETCH = gql`
    query FetchResource($id: MongoID!) {
        getAdminUser(_id: $id) {
            _id
            name
            email
            role {
                title
                _id
            }
        }
    }
`;

const FETCH_DATA = gql`
    query FetchUserData {
        listPermissionRoles {
            items {
                _id
                title
            }
        }
    }
`;

const EDIT = gql`
    mutation EditAdminUser(
        $record: UpdateOneAdminUserInput!
        $filter: FilterUpdateOneAdminUserInput!
    ) {
        editAdminUser(record: $record, filter: $filter) {
            _id
        }
    }
`;

const CREATE = gql`
    mutation CreateAdminUser($record: CreateOneAdminUserInput!) {
        createAdminUser(record: $record) {
            _id
        }
    }
`;

const RESETPASSWORD = gql`
    mutation ForgotPassword($email: String) {
        forgotAdminPassword(email: $email) {
            email
        }
    }
`;

const EditForm = () => {
    const { type, id } = useParams();
    const toast = useToast();

    const [fetchData, { data }] = useLazyQuery(FETCH, {
        variables: { id: id },
    });

    const { data: userData } = useQuery(FETCH_DATA);
    const [resetPassword, { loading }] = useMutation(RESETPASSWORD);

    const useValidation = () => {
        if (type === 'create') {
            return yup.object().shape({
                name: yup.string().required('Required'),
                email: yup.string().email().required('Required'),
                password: yup.string().min(8).required('Required'),
                password_confirm: yup.string().when('password', {
                    is: (val) => val && val.length > 0, // alternatively: (val) => val == true
                    then: yup
                        .string()
                        .required('You must confirm your password')
                        .oneOf([yup.ref('password')], "Passwords don't match"),
                }),
            });
        }
        if (type === 'edit') {
            return yup.object().shape({
                name: yup.string().required('Required'),
                email: yup.string().email().required('Required'),
                password: yup.string('Required'),
                password_confirm: yup.string().when('password', {
                    is: (val) => val && val.length > 0, // alternatively: (val) => val == true
                    then: yup
                        .string()
                        .required('You must confirm the password')
                        .oneOf([yup.ref('password')], "Passwords don't match"),
                }),
            });
        }
    };

    const handleSendReset = async () => {
        try {
            await resetPassword({
                variables: { email: data.getAdminUser.email },
            });
            toast({
                status: 'success',
                title: 'Success',
                description:
                    'An email has been sent to the user with instructions on how to reset their password.',
            });
        } catch {}
    };

    return (
        <EditView
            setup={setup}
            fetchData={fetchData}
            data={data}
            validationSchema={useValidation()}
            gqlFetch={FETCH}
            gqlCreate={CREATE}
            gqlEdit={EDIT}
            isFullWidth
            ignoreValues={['password_confirm']}
        >
            <Container
                maxWidth='600px'
                w='100%'
                p='20px'
                margin='25px auto'
                variant='card'
            >
                <Field
                    auto
                    isFullWidth
                    name='name'
                    component='text'
                    label='Name'
                    rules={{ required: true }}
                />

                <Field
                    auto
                    name='role'
                    component='select'
                    options={
                        userData?.listPermissionRoles
                            ? userData?.listPermissionRoles?.items
                            : []
                    }
                    label='Role'
                    labelKey='title'
                    valueKey='_id'
                    selectValue='value'
                    rules={{ required: true }}
                />

                <Field
                    auto
                    isFullWidth
                    name='email'
                    component='text'
                    type='email'
                    label='Email Address'
                    rules={{ required: true }}
                />

                {/* {type === 'edit' && (
                    <Button
                        mt='20px'
                        isLoading={loading}
                        onClick={() => handleSendReset()}
                        colorScheme='blue'
                    >
                        Send Reset Password Email
                    </Button>
                )} */}

                <Box mt='25px'>
                    <Field
                        auto
                        isFullWidth
                        name='password'
                        autoComplete='off'
                        component='text'
                        label={
                            type === 'create' ? 'Password' : 'Change Password'
                        }
                        rules={{ required: true }}
                    />
                    <Field
                        auto
                        isFullWidth
                        name='password_confirm'
                        autoComplete='address'
                        component='text'
                        label={
                            type === 'create'
                                ? 'Confirm Password'
                                : 'Confirm New Password'
                        }
                        rules={{ required: true }}
                    />
                </Box>
            </Container>
        </EditView>
    );
};

export default EditForm;
